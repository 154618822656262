@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.community {
  @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 3rem 0;
  }
  @media #{$mq-lg} {
    padding: 1rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 5vh 0;

  &--grid {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
  }
}

.community .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 2rem;
  margin-bottom: .5rem;
  border-radius: 24px;
  min-height: 40vh;
  background-image: url(https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/d8d671c9-cf56-4aaf-9bc7-2c32f078ea00/public);
  background-position: top;
  object-fit: contain;

  h1 {
    @extend %body-lg;
    text-align: left;
    font-weight: bolder;
    color: $gray-100;
    margin-block-end: 1rem;
  }
}

.social-grid {
  display: grid;
  @media #{$mq-md} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: .25rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    
    .social-item {
      padding: 2rem;
      width: 100%;
      background: $gray-300;
      border-radius: 12px;
      margin-bottom: .25rem;
      cursor: pointer;
      transition: all .2s ease-out;
      color: $black;
      &:hover {
        background: $gray-400;
      }
    }
  }

  .featured-content {
    background-image: url(https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/6bede382-7fea-4406-deb5-8c03e1c15600/public);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-radius: 12px;
    &--item {
      padding: 2rem;
      @extend %body;
      color: $gray-200;
      text-decoration: none;
      cursor: pointer;
      transition: all .2s ease-in;
      &:hover {
        color: $gray-800;
      }
    } 
  }
}