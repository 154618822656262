@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/typography";

$scroll-width: 16rem;
$content-width: calc(94rem - #{$scroll-width});

.careers {
  @media #{$mq-lg} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
    "hero hero hero hero"
    "scrool work work work"
    "scrool stories stories stories"
    "scrool values values values"
    "scrool benefits-info benefits-info benefits-info"
    "scrool benefits benefits benefits"
    "scrool positions positions positions"
    "scrool cta cta cta"; 
  }

  display: grid;
  grid-template-rows: auto auto auto auto auto;
  gap: 0 0;
  grid-template-areas:
    "hero"
    "work"
    "stories"
    "values"
    "benefits-info"
    "benefits"
    "positions"
    "cta";
    
  background-color: white;

  // background's
  .values-background {
    grid-area: 4 / 1 / 5 / 5;
    background-color: $text;
  }

  .benefits-background {
    grid-area: 6 / 1 / 7 / 5;
    background-color: $gray-300;
  }


  .scroll {
    grid-area: scrool;
    width: 15vw;
    margin-top: 4rem;
    margin-bottom: 8rem;

    mix-blend-mode: difference;
    color: black;

    display: none;
    @media #{$mq-lg} {
      display: block;
    }

    nav {
      position: sticky;
      top: calc(5rem + 60px);
    }

    a {
      display: block;
      position: relative;
      color: white;
      text-decoration: none;
      width: min-content;
      white-space: nowrap;
      transition: all 300ms;

      opacity: 0.75;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.05rem;
        background-color: white;
        transition: opacity 300ms, transform 300ms;

        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }

      &:hover::after {
        transform: scale(1);
      }
    }

    &--sub-menu {
      &:before {
        opacity: 0.5;
        content: "";
        width: 1.75rem;
        height: 0.12rem;
        margin-right: 0.5rem;
        margin-left: -2.25rem;
        display: inline-block;
        background-color: white;
        vertical-align: middle;
      }
    }

    &__active {
      opacity: 1;
      font-weight: 500;

      &:before {
        content: "";
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        margin-right: 0.6rem;
        margin-left: -1rem;
        display: inline-block;
        background-color: white;
        vertical-align: middle;
      }
    }

    &--sub-menu__active {
      font-weight: 500;

      &:before {
        content: "";
        width: 1.75rem;
        height: 0.12rem;
        margin-right: 0.5rem;
        margin-left: -2.25rem;
        display: inline-block;
        background-color: white;
        vertical-align: middle;
      }
    }

    li {
      margin-bottom: 1rem;

      &:first-child {
        margin-top: 1rem;
      }
    }

    &--animation {
      &-enter {
        max-height: 0;
        opacity: 0;
      }

      &-enter-active {
        max-height: 1.125rem;
        opacity: 1;
        transition: all 300ms;
      }

      &-exit {
        max-height: 1.125rem;
        opacity: 1;
      }

      &-exit-active {
        max-height: 0;
        opacity: 0;
        transition: all 300ms;
      }
    }
  }

  .hero {
    grid-area: hero;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: calc(60vh - 40px);
    background-color: $black;
    
    &--content {
      @media #{$mq-lg} {
        padding: 0 10rem;
        max-width: 60rem;
      }
      margin-top: auto;
      margin-bottom: auto;
      padding: 0 1rem;
      
    }
    
    h2 {
      @extend %h1;
      color: $gray-100;
    }

    svg {
      position: absolute;
      right: max(calc((100vw - 70rem) / 2), 2rem);;
      bottom: 2rem;
      padding: 0.5rem;
      margin: 0.5rem;
      cursor: pointer;
      color: $gray-500;
      border-radius: 0.25rem;

      &:hover {
        background-color: $gray-800;
      }
    }
  }

  .work {
    grid-area: work;
    
    .why-work {
      @media #{$mq-lg} {
        justify-self: flex-end;
        column-gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
        max-width: $content-width;
      }
      display: grid;
      grid-template-columns: 1fr;
      margin: 2rem 1rem;

      div {
        justify-self: flex-end;
      }

        img {
          height: 30vh;
          object-fit: cover;
          object-position: center;
          
          @media #{$mq-lg} {
            height: unset;
            width: 100%;
            padding: 0;
            align-self: center;
            justify-self: flex-start;
        }
      }

      p {
        @media #{$mq-md} {
          width: 90%;
        }
        @extend %body;
        color: $gray-900;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      h3 {
        @extend %body-lg;
        font-weight: 600;
        margin-block-start: 0;
        margin-block-end: .5rem;
      }

      h4 {
        @extend %body;
        color: $gray-800;
        margin-block-end: 0.5rem;
      }
    }
}

  }

  .stories {
    grid-area: stories;
    
    @media #{$mq-lg} {
      max-width: $content-width;
    }
    
    &--description {
      @media #{$mq-md} {
        padding: 1.5rem 1rem;
      }
      
      @media #{$mq-lg} {
        flex-direction: row;
      }
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      column-gap: 5rem;


      p {
        @media #{$mq-md} {
          max-width: 30rem;
        }
        @extend %body;
        color: $text;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      h3 {
        @media #{$mq-md} {
          margin: 0 0 2rem;
        }
        @extend %h1;
        color: $text;
        margin: .5rem 0;
      }
    }

    &--stores {
      @media #{$mq-md} {
        padding: 1rem 1rem 2rem;
        flex-direction: row;
      }
      max-width: $content-width;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 1rem;
      gap: .5rem;
      
    }

    &--story {
      width: 100%;

      h5 {
        margin: 0;
      }

      @media #{$mq-md} {
        width: 48%;
      }

      @media #{$mq-lg} {
        width: 30%;
      }

      img {
        width: 100%;
        height: 10rem;
        object-fit: cover;
      }
    }
  }

  .values {
    grid-area: values;
    padding: 6rem 1rem;
    min-height: 10vh;
  }

  .cta {

    h3 {
      @extend %h1;
      color: $text;
      margin: 0 0 1rem;
    }
    grid-area: cta;
    padding: 1rem 1rem 3rem;

  }

  .benefits-info {
    grid-area: benefits-info;
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem;
    column-gap: 5rem;
    
    @media #{$mq-md} {
      padding: 4rem 1rem;
    }

    @media #{$mq-lg} {
      max-width: $content-width;
      flex-direction: row;
    }

    p {
      max-width: 30rem;
      @extend %body;
      color: $text;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    h3 {
      @extend %h1;
      color: $text;
      margin: 0 0 2rem;
    }

    h4 {
      margin: 0 0 .5rem;
      @extend %body;
      color: $gray-800;
    }
  }

  .benefits {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      padding: 5rem 1rem;
      column-gap: .5rem;
    }
    
    @media #{$mq-lg} {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
    }
    
    grid-area: benefits;
    
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    column-gap: 1rem;
    padding: 3rem 1rem;
    
    
    h3 {
      grid-area: 1 / 1 / 1 / 2;
      @media #{$mq-md} {
        grid-area: 1 / 1 / 1 / 3;
      }
      @media #{$mq-lg} {
        grid-area: 1 / 1 / 1 / 4;
      }
      @extend %h1;
      margin: 1.5rem 0;
      color: $text;
    }
    
    .benefit {
      @media #{$mq-md} {
        width: 15rem;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      width: 100%;

      @extend %body;
      color: $text;
      font-size: 1.25rem;
      line-height: 1.75rem;

      svg {
        transform: translateY(0.2rem);
      }
    }
  }

  .positions {
    @media #{$mq-md} {
      padding: 5rem 1rem 10rem 1rem;
    }
    
    @media #{$mq-lg} {
      max-width: $content-width;
    }
    
    grid-area: positions;
    
    padding: 5rem 1rem;


    h3 {
      @extend %h1;
    }

    &--animation {
      &-enter {
        max-height: 0;
        opacity: 0;
      }

      &-enter-active {
        max-height: 3.75rem;
        opacity: 1;
        transition: all 400ms, opacity 300ms;
      }

      &-exit {
        max-height: 3.75rem;
        opacity: 1;
      }

      &-exit-active {
        max-height: 0;
        opacity: 0;
        transition: all 400ms, opacity 300ms;
      }
    }

    &--list-container {
      margin: 0;
      padding: 0;
      list-style: none;

      border-bottom: 0.06rem solid $gray-900;

      li {
        border-top: 0.06rem solid $gray-900;
      }
    }

    a {
      @extend %body;
      color: $text;
      text-decoration: none;
      font-weight: 600;
    }

    p {
      margin: 0;
      padding-block-start: 1rem;
      padding-block-end: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-radius: 0.25rem;
      transition: background-color 300ms;

      &:hover {
        background-color: $gray-300;
      }
    }
  }
