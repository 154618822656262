@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.events {
  @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 3rem 0;
    margin-top: 0vh;
  }
  @media #{$mq-lg} {
    padding: 1rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin-top: 5vh;
  &--container {
    margin: 0 2rem;
  }
}

.events-header {
  @media #{$mq-sm} {
    align-items: flex-start;
    padding: 3rem 0;
  }
  @media #{$mq-lg} {
    padding: 5rem 0
  }
  display: flex;
  flex-direction: column;
  margin: 5vh 0;
}

.title {
  @extend %h1;
  text-align: left;
  color: $black;
}

.event-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  p {
    @extend %body-lg;
  }
}

.event {
  @media #{$mq-md} {
    grid-template-columns: 3fr 1fr;
  }
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  border-bottom: 1px solid $gray-800;
  padding: .4rem;
  &:first-of-type {
    border-top: 1px solid $gray-800;
  }
  p {
    @extend %body;
    text-align: left;
    align-self: center;
  }
  a {
    color: $gray-900;
    transition: 0.15s ease-out;
    &:hover {
      color: $gray-700;
    }
  }
  .date { 
      text-align: right;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    span {
      @extend %caption;
      margin-left: 1rem;
      justify-self: end;
      align-self: center;
      padding: .2rem .5rem;
      border-radius: .25rem;
      background: $primary;
      max-inline-size: 6rem;
  }
}

.events .double-column {
  @media #{$mq-md} {
    flex-direction: row;
  }
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem 0; 
  
  p {
    @media #{$mq-md} {
      margin-block-start: 1rem;
      margin-block-end: 1rem;
    }
    @extend %body-lg;
    border-bottom: 1px solid $gray-800;
    padding-bottom: 1rem;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
  }
  
  div {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  .left-column {
    p {
      @media #{$mq-lg} {
        margin-right: 2rem;
      }
    }
  }
}


.links {
  @media #{$mq-md} {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  a {
    @media #{$mq-md} {
      margin-right: 1rem;
      margin-top: 0;
    }
    margin-top: .5rem;
    @extend %body;
    color: $gray-900;
    text-decoration: none;
    transition: 0.15s ease-out;
    &:hover {
      color: $gray-700;
    }
  }
}
