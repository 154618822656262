@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.products {
  flex-direction: column;
  display: flex;
  justify-content: left;
  background: #0f0f0f;

  &--container {
    @media #{$mq-lg} {
    margin: 2rem $margin-lg;
    padding: 5rem 0;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    margin: 2rem $margin-sm;
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
  }
    &--grid {
    @media #{$mq-md} {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 5vh;
    display: grid;
  }
}

.products--header {
  @media #{$mq-lg} {
    width: 65%;
  }
  @media #{$mq-sm} {
    align-items: flex-start;
    padding: 1rem 0;
  }
  display: flex;
  margin: 0;
  flex-direction: column;

  .title {
    @extend %h1;
    text-align: left;
    margin-block-end: 0;
    letter-spacing: .05px;
    font-weight: bolder;
    color: $gray-100;
  }
}

.products .description { 
  @media #{$mq-md} {
    width: 50%
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    @extend %body;
    margin: .5rem 0;
    color: $gray-100;
  }
}

.products .product {
  color: white;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-radius: 1.5rem;
  transition: .15s;
  
  &:hover {
    background: $gray-900;
    filter: brightness(.9);

    .visit {
      button {
        background: black;
      }
    }
  }


  h2 {
    @extend %body-lg;
    font-weight: bolder;
    margin-block-start: .5rem;
    margin-block-end: 0;
  }

  a {
    letter-spacing: 0.5px;
    button {
      width: 60%;
    }
    &:visited {
      color: white;
    }
  }

  img {
    width: 100%;
    border-radius: 32px;
    @media #{$mq-md} {
      // max-height: 300px;
    }
  }

  .description {
    @extend %body;
    color: $gray-700;
    width: 100%;
    min-height: 7rem;
  }

}

