@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.not-found {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.not-found button {
  padding: .66rem 1.5rem;
  &:visited {
    color: white;
  }
}
