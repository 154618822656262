// global color setup
:root {
  --primary-color-hue: 60;
  --primary-color-saturation: 100%;

  --gray-color-hue: 0;
  --gray-color-saturation: 0%;
}

// sass variables
$primary: hsl(var(--primary-color-hue), var(--primary-color-saturation), 64%);

$gray-100: hsl(var(--gray-color-hue), var(--gray-color-saturation), 100%); //#FFF

$gray-200: hsl(var(--gray-color-hue), var(--gray-color-saturation), 98%); //#FAFAFA

$gray-300: hsl(var(--gray-color-hue), var(--gray-color-saturation), 96%); //#F5F5F5

$gray-400: hsl(var(--gray-color-hue), var(--gray-color-saturation), 91%); //#E8E8E8

$gray-500: hsl(var(--gray-color-hue), var(--gray-color-saturation), 85%); //#D9D9D9

$gray-600: hsl(var(--gray-color-hue), var(--gray-color-saturation), 75%); //BFBFBF

$gray-700: hsl(var(--gray-color-hue), var(--gray-color-saturation), 55%);

$gray-800: hsl(var(--gray-color-hue), var(--gray-color-saturation), 35%);

$gray-900: hsl(var(--gray-color-hue), var(--gray-color-saturation), 15%);

$text: hsl(var(--gray-color-hue), var(--gray-color-saturation), 8%);

$black: hsl(var(--gray-color-hue), var(--gray-color-saturation), 0%);
