@import "src/styles/breakpoints";

.container {
  margin: 0;
  // padding: 1rem 0;

  @media #{$mq-sm} {
    // margin: 1rem;
    // padding: 2rem;
  }

  @media #{$mq-lg} {
    // margin: 2rem;
    // padding: 4rem;
  }
}