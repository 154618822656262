@import 'src/styles/colors';
@import 'src/styles/breakpoints';
@import 'src/styles/typography';
@import 'src/styles/animations';
.single-audit-page {
  .audits-items__container {
    padding: 0 2rem 4rem 2rem;
  }
}
.single-audit {
  img {
    width: 80%;
  }
  h1 {
    @extend %h1;
  }
  p {
    @extend %body-lg;
  }
}
.single-audit__wrapper {
  padding: 5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  .single-audit__wrapper__date {
    position: absolute;
    top: 2.875rem;
    margin: 0;
  }
}
.single-audit__button-wrapper {
  max-width: 23rem;
  display: flex;
  width: 100%;
  margin-top: 1rem;
  a {
    width: 100%;
  }
  button {
    background-color: $black;
    color: $gray-200;
    width: 100%;
    padding: .5rem;
    font-weight: 600;
    @extend %link;
  }
  a + a {
    margin-left: 1rem;
  }
}
.single-audit__info {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: auto;
  border-top: 1px solid $gray-300;
  border-left: 1px solid $gray-300;
  align-self: end;
  div {
    border-bottom: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    display: flex;
    padding: 0 .5rem;
    align-items: center;
    min-height: 3.5rem;
    @extend %body;
  }
}
@media #{$mq-md} {
  .single-audit-page .audits-items__container {
    padding: 0 4.25rem 4rem 4.25rem;
  }
  .single-audit__wrapper {
    padding: 6.375rem 4.25rem 8.625rem 4.25rem;
  }
  .single-audit__info {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}
@media #{$mq-lg} {
  .single-audit-page .audits-items__container {
    padding: 0 4rem 16rem 4rem;
    max-width: 112rem;
    margin: 0 auto;
  }
  .single-audit__wrapper {
    max-width: 112rem;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 6rem 4rem 4rem 4rem;
    & > div {
      width: 45%;
    }
  }
  .single-audit__info {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}
