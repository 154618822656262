@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/breakpoints";

$dot-size: 1.625rem;

.dot-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 1rem 1.5rem;
  justify-content: start;
  align-content: start;
}

.dot {
  width: $dot-size;
  height: $dot-size;
  border: 0.06rem solid $gray-200;
  border-radius: calc(#{$dot-size} / 2);
  cursor: pointer;
  transition: background-color 300ms;

  &--selected {
    background-color: $primary !important;
    cursor: default;
  }

  &:hover {
    background-color: $gray-800;
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media #{$mq-md} {
    flex-direction: row;
    gap: 3rem;
  }

  &--data {
    max-width: 25rem;
    margin-right: auto;

    @media #{$mq-lg} {
      // height: 20rem;
    }

    h3 {
      @extend %body;
      color: $gray-700;

      margin-top: 2.5rem;

      @media #{$mq-lg} {
        margin-top: 0;
      }
    }

    h2 {
      @extend %h1;
      color: $gray-200;
      margin: 0.5rem 0;
    }

    p {
      @extend %body;
      color: $gray-200;
    }
  }

  &--animation {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0%);
    }
    &-exit .btn {
      opacity: 1;
      transform: translateY(0%);
    }
    &-exit-active {
      opacity: 0;
      transform: translateY(100%);
    }
    &-enter-active,
    &-exit-active {
      transition: opacity 400ms, transform 300ms;
    }

  }
}
