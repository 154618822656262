@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.home {
  flex-direction: column;
  display: flex;
  justify-content: left;
  background: $gray-100;

  &--container {
    @media #{$mq-lg} {
    margin: 2rem $margin-lg;
    padding: 5rem 0;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    margin: 2rem $margin-sm;
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
  }
    &--grid {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 5vh;
    display: grid;
  }

  &--options {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 10vh;
    }
    @media #{$mq-lg} {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: .5rem;
    }
    grid-template-columns: 1fr;
    margin-top: 20vh;
    display: grid;
  }
}

.home button {
    padding: .66rem 1.5rem;
    &:visited {
      color: white;
    }
  }

.home--hero {
  margin: 3rem 0;
  width: 100%;

  .title {
    @media #{$mq-lg} {
      align-items: center;
    }
    @media #{$mq-sm} {
      padding: 1rem 0;
    }
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    
    &--h1 {
      @media #{$mq-md} {
        max-width: 900px;
        font-size: 3.125rem;
        line-height: 3.5rem;
      }
      @media #{$mq-xl} {
        max-width: 1200px;
      }
      @media #{$mq-lg} {
        text-align: center;
      }
      @extend %h1;
      font-size: 2.8rem;
      line-height: 3rem;
      margin-block-end: 0;
      text-align: left;
      max-width: 90%;

      span {
        text-decoration: underline;
      }
    }
    &--h2 {
      @media #{$mq-xl} {
        max-width: 1200px;
      }
      @media #{$mq-lg} {
        text-align: center;
      }
      @extend %body-lg;
      max-width: 800px;
      margin-block-end: 1rem;
      text-align: left;
    }
    &--btn {
      cursor: pointer;
    }
  }
}

.home .option {
  @media #{$mq-md} {
    padding: .5rem;
  }
  display: flex;
  flex-direction: column;
  transition: all .3s ease-in;
  border-radius: 1.5rem;
  min-height: 300px;
  transition: .15s;

  &:hover {
    @media #{$mq-md} {
      background: $gray-100;
      filter: brightness(.9);
    }
  }
  
  a {
    @extend %body-lg;
    color: $black;
    font-weight: 600;
    letter-spacing: .4px;
    margin-block-start: .5rem;
    margin-block-end: 0;
  }
  img {
    @media #{$mq-lg} {
      width: 100%;
    }
    border-radius: 1.5rem;
    height: 30vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }


  .description {
    @extend %body;
    color: $black;
    width: 100%;
    min-height: 2rem;
    margin-block-start: .5rem;
  }
}

.home-callout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media #{$mq-xl} {
    justify-content: flex-start;
  }
  @media #{$mq-lg} {
    flex-direction: row;
  }
  margin: 2rem 0;
}


.home-callout .column {
  @media #{$mq-md} {
    padding: 0;
    flex-direction: row;
  }
  @media #{$mq-lg} {
    align-items: center;
    flex-direction: row;
    padding: 3rem 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  
  &:first-of-type {
    margin-bottom: 1rem;
    
    @media #{$mq-lg} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
  }

  &--content-wrapper {
    @media #{$mq-md} {
      padding: 0 0.5rem;
      width: 100%;
    }

    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    h1 {
      @extend %body;
      margin-block-start: 1.5rem;
      margin-block-end: 0;
      width: 100%;
      color: $gray-800;
    }
    .text-wrapper {
      width: 100%;
      p { 
        @extend %body-lg;
        margin-block-start: 1rem;
        margin-block-end: 0;
        font-size: 32px;
        line-height: 40px;
        @media #{$mq-xl} {
          max-width: 60rem;
        }

      }
    }
  }
}

.home-clients {
  @media #{$mq-md} {
    margin: 2rem 0;
    padding: 2rem 1rem;
    align-items: center;
  }
  padding: 2rem 0;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--row1, &--row2 {
    img { 
      @media #{$mq-md} {
        padding: unset;
        grid-gap: 1.5rem;
        column-gap: 2.5rem;
      }
      @media #{$mq-lg} {
        width: 100%;
        max-width: 11.25rem;
      }
      max-width: 9.375rem;
      padding: 1rem;
      cursor: pointer;
      transition: 0.2s ease-in;
      &:hover { 
        transform: translateY(-5px);
      }
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  &--row1 {
    @media #{$mq-md} {
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
    }
  }
  
  &--row2 {
    @media #{$mq-lg} {
      grid-template-columns: repeat(5, 1fr);
    }
    @media #{$mq-md} {
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      margin-top: unset;
    }
  }

  h3 {
    @extend %body-lg;
    font-weight: bolder;
  }
}

.backed-by {
  @media #{$mq-md} {
    margin: 2rem 0;
    padding: 2rem 1rem;
    align-items: center;
  }
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem 0;
  padding: 0;
  
  &--wrapper {
    @media #{$mq-md} {
      grid-template-columns: repeat(5, 1fr);
    }
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    align-items: baseline;
    justify-content: center;
    display: grid;
  }

  h3 {
    @extend %body-lg;
    font-weight: bolder;
  }
  &--card {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img { 
      max-width: 4.85rem;
      width: fit-content;
      margin-bottom: 1rem;
      justify-self: center;
      filter: grayscale(100%);

      p {
        @extend %body;
      }
    }
  }
}

.home-hire {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  @media #{$mq-lg} {
    align-items: flex-end;
    flex-direction: row;
    column-gap: 4rem;
    margin: 8rem 0;

    &--item {
      @media #{$mq-md} {
        width: 50%;
      }
      width: 100%;
    }
    &--item:first-of-type {
      @media #{$mq-lg} {
        padding-left: .5rem;
      }
    }
  }

  @media #{$mq-md} {
    margin: 4rem 0; 
  }
  
  h1 {
    @media #{$mq-lg} {
      max-width: 15rem;
      margin-bottom: .5rem;
    }
    @extend %body;
    color: $gray-700;
    font-weight: normal;
    margin: 0;
  }

  h2 {
    @extend %h1;
    margin: 0;
  }

  button {
    @media #{$mq-md} {
      width: 20rem;
    }
  }
  
}

.home-reviews {
  @media #{$mq-md} {
    flex-direction: row;
  }
  background-color: $gray-200;
  display: flex;
  padding: 3rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  .left-column {
    @media #{$mq-md} {
      flex-direction: row;
      width: 40%;
      padding: 0 2rem;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

    .title {
      @extend %body-lg;
      letter-spacing: .25px;
      font-weight: bolder;
    }
  }
  .right-column {
    @media #{$mq-md} {
      width: 70%;
      margin-right: 1rem;
    }
    display: flex;
    margin-bottom: 1rem;
    
    &--content-wrapper {
      @media #{$mq-md} {
        grid-template-columns: minmax(1fr);
      }
      display: grid;
      grid-template-columns: 1fr;

      .review {
        background: $gray-400;
        padding: .5rem 1rem;
        border-radius: .5rem;
        margin-bottom: 1rem;
        margin-right: 1rem;

        &--text {
          @extend %body;
          font-size: 1.125rem;
          line-height: 1.375;
        }
        &--caption {
          @extend %body;
          font-size: 1.125rem;
          line-height: 1.375;
          font-weight: bolder;
        }
      }
    }
  }
}

.home-careers {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  align-items: flex-start;
  row-gap: .5rem;
  @media #{$mq-md} {
    align-items: center;
    flex-direction: row;
    column-gap: 1rem;
    &--column:first-of-type {
      img {
        max-width: 80%;
      }
    }
  }
  &--column:first-of-type {
    img {
      width: 80%;
    }
  }

  &--column {
    @media #{$mq-md} {
      width: 50%;
    }
    width: 100%;
    &--content-wrapper {
      display: flex;
      flex-direction: column;

      h1 {
        @extend %body-lg;
        margin-block-end: .25rem;
        font-weight: bolder;
      }
      p {
        @extend %body;
        margin-block-start: .25rem;
      }
      button {
        max-width: 20rem;
      }
    }
  }
}