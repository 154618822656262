@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.public-goods {
  flex-direction: column;
  display: flex;
  justify-content: left;
  background: #0f0f0f;

  &--container {
    @media #{$mq-md} {
    margin: 2rem $margin-lg;
    padding: 5rem 0;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    margin: 2rem $margin-sm;
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
  }

  &--grid {
    @media #{$mq-sm} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$mq-md} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media #{$mq-lg} {
      grid-template-columns: repeat(4, 1fr);
    }
    margin-top: 5vh;
    display: grid;
    grid-gap: 1rem;
  }
}

.public-goods .header {
  @media #{$mq-lg} {
    width: 65%;
  }
  @media #{$mq-sm} {
    align-items: flex-start;
    padding: 1rem 0;
  }
  display: flex;
  margin: 0;
  flex-direction: column;
  
  .title {
    @extend %h1;
    text-align: left;
    margin-block-end: 0;
    letter-spacing: .05px;
    font-weight: bolder;
    color: $gray-100;
  }
}


.public-goods .description { 
  @media #{$mq-md} {
    width: 50%
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  h4 {
    @extend %body;
    margin: .5rem 0;
    color: $gray-100;
  }
}

.public-goods .project {
  @media #{$mq-sm} {
    border: 1px solid $gray-900;
  }
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 1.5rem;
  transition: .15s;
  
  &:hover {
    background: #0f0f0f;
    filter: brightness(.9);
    .visit {
      button {
        filter: brightness(.7);
      }
    }
  }

  h2 {
    @extend %body-lg;
    font-size: 32px;
  }

  .visit {
    letter-spacing: 0.5px;
    width: 100%;
    text-align: center;
    button {
      width: 80%;
      padding: 1rem .5rem;
    }

  }
  .description {
    @extend %body;
    color: $gray-700;
    width: 90%;
    text-align: center;
    min-height: 5rem;
  }

  .thumbnail-container {
    min-height: 8rem;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thumbnail {
    max-height: 10rem;
    // width: auto;
    width: 80%;
  }
}

.public-goods .project:nth-of-type(1) {
  .tags {
    color: lightskyblue;
  }
}
.public-goods .project:nth-of-type(2) {
  .tags {
    color: violet;
  }
}
.public-goods .project:nth-of-type(3) {
  .tags {
    color: cornflowerblue;
  }
}
.public-goods .project:nth-of-type(4) {
  .tags {
    color: orange;
  }
}
.public-goods .project:nth-of-type(5) {
  .tags {
    color: orangered;
  }
}