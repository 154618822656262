@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.services {
  @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 5rem 0;
    margin: 2rem $margin-lg;
  }
  @media #{$mq-lg} {
    padding: 2rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 0 $margin-sm 0 $margin-sm;

  &--header {
    @media #{$mq-md} {
      padding: 5rem 0;
      margin-top: 0vh;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
    overflow-x: hidden;

    .headerTextWrapper {
      @media #{$mq-sm} {
        align-items: flex-start;
      }
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
    
      h4 {
        @extend %body-lg;
        margin-block-start: .8rem;
        margin-block-end: .8rem;
        @media #{$mq-md} {
          width: 50%;
        }
        width: 90%;
      }

      .titleText {
        @extend %h1;
        text-align: left;
        margin: auto 0 0.25rem 0;
        color: $black;
        font-weight: bolder;
        letter-spacing: 0.01rem;
      }
  }
  
  }
}

.service-container {
  @media #{$mq-md} {
    grid-template-columns: repeat(2, 1fr);
    margin-top: unset;
  }
  @media #{$mq-lg} {
    grid-template-columns: repeat(3, 1fr);
  };
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
}

.services .service {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: .5rem;
  border-radius: 1.5rem;
  min-height: 300px;
  transition: .15s;

  &:hover {
    background: $gray-100;
    filter: brightness(.9);
  }
  
  h1 {
    @extend %body-lg;
    margin: .5rem 0 0 0;
  }
  width: 100%;
  color: $black;
  
  img {
    width: 100%;
    border-radius: 20px;
  }
}
