@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.solutions {
    @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 5rem 0;
    margin: 2rem $margin-lg;
  }
  @media #{$mq-lg} {
    padding: 2rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 0 $margin-sm 0 $margin-sm;

  &--container {
    @media #{$mq-md} {
    padding: 5rem 0;
    margin-top: 0vh;
    }
    @media #{$mq-lg} {
      padding: 2rem 0;
    }
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-top: 5vh;
  
  &--header {
    @media #{$mq-sm} {
      align-items: flex-start;
      padding: 3rem 0;
    }
    @media #{$mq-lg} {
      padding: 5rem 0
    }
    display: flex;
    flex-direction: column;
    margin: 5vh 0;
  }
  
  .title {
    @extend %h1;
    text-align: left;
    color: $black;
    font-weight: bolder;
  }
  
  a:visited {
    color: white;
  }
  
  &--description { 
    @media #{$mq-md} {
      width: 50%
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
  
  }
  
  h4 {
    @extend %body-lg;
    margin: 1rem 0;
  }
}
}



.case-study {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;

  img {
    width: 100%;
    margin-bottom: .5rem;
  }

  .double-column {
    @media #{$mq-md} {
      margin: 0 2rem;
    }
  }
}

.solutions .double-column {
  @media #{$mq-md} {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  h4 {
    width: 50%;
    padding-right: 1rem;
  }
  
  p {
    @media #{$mq-md} {
      width: 50%;
    }
    margin: .5rem 0;
    width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 50%;
    p {
      width: 100%;
    }
  }
}

.solutions .featured-service { 
  background: #f5f5f5;
  width: 100%;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;

  .double-column {
    h4 {
      width: 100%;
    }
    div {
      width: 100%;
      margin-bottom: 2rem;
    } 
  }
}

.solutions .items { 
  @media #{$mq-md} {
    grid-template-columns: repeat(4, 1fr);;
    margin-top: 1rem;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  .item {
    width: 100%;
    div { 
      height: 140px;
      background: gray;
      margin-right: 1rem;
    }
  }
}


.solutions .body-text {
  @extend %body;
  font-size: 1.25rem;
  @media #{$mq-md} {
    margin: .5rem 0;
  }
  margin: .25rem 0 1rem 0;
}

.solutions .service {
  @media #{$mq-md} {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  margin: 2rem;
  
  h4 {
    @media #{$mq-md} {
      width: 50%;
    }
    width: 100%;
  }
  &--container {
    @media #{$mq-md} {
      width: 50%;
    }
    width: 100%;
  }
}