@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/typography";
@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.footer {
  @media #{$mq-xl} {
    width: 100vw;
  }
  width: 100%;
  position: relative;
  bottom: 0;
  padding-top: 5vh;
  background: $black;
  display: flex;
  flex-direction: column;

  &--upper {
    @media #{$mq-md} {
      flex-direction: row;
      align-items: center;
    }
    @media #{$mq-md} {
      justify-content: space-between;
    }
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    
    &--left {
      @media #{$mq-md} {
        width: 80%;
        display: flex;
      }
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &--lower {
    @media #{$mq-md} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 3rem;
    }
    display: flex;
    height: auto;
    justify-content: flex-start;
  }
}

.footer-column {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  a {
    @extend %body;
    color: $gray-100;
    text-decoration: none;
    transition: all 0.2s ease-in;
    &:hover {
      color: $gray-800;
    }
  }
  &--header {
    margin-bottom: .25rem;
  }

}

.footer-column {
  @media #{$mq-md} {
  }
  margin: 2rem;
  @extend %body;
  display: flex;
  flex-direction: column;

  &--header {
    margin-bottom: .25rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    @media #{$mq-xxl} {
      font-size: 1.5rem;
    }
    @extend %body;
    font-size: 1.2rem;
    color: $gray-100;
    text-decoration: none;
    transition: all 0.1s ease-in;
    &:hover {
      color: $gray-800;
    }
  }
  
  a {
    text-decoration: none;
  }
  }
.footer--upper--right {
  display: flex;
  flex-direction: column;
  @media #{$mq-md} {
    margin-right: 1rem;    
    align-items: flex-end;
  }

  .footer-link {
    margin-bottom: .5rem;
    button {
      @media #{$mq-md} {
        width: unset;
      }
      width: 100%;
    }
  }
  margin: 0 0 1rem 2rem;
  p {
    @extend %body;
    color: $gray-200;
    text-align: right;
    margin-block-start: 0;
    margin-block-end: .5rem;
  }
  .icons {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    a {
      margin: 0 .25rem;
      color: $gray-700;
      transition: all 0.25s;
      &:hover {
        color: $primary;
      }
    }
  }
}

.footer .rule {
  background: $gray-800;
  width: 97%;
  align-self: center;
  height: 1px;
  margin: 0;
}

.footer .bottom-left {
  @media #{$mq-md} {
    left: 1rem;
  }
  display: flex;
  position: relative;
}
.footer .bottom-right {
  @media #{$mq-md} {
    right: 1rem;
    margin-left: 2rem;
  }
  display: flex;
  margin-left: 1rem;
  position: relative;
}

.footer .bottom-link {
  @media #{$mq-md} {
    margin: 0 .5rem;
  }
  @extend %caption;
  font-size: 12px;
  line-height: 16px;
  color: $gray-600;
  text-decoration: none;
  margin: .5rem;
  transition: all .25s ease-in;
  &:hover {
    color: $primary;
  }
}
