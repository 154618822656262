@import "src/styles/colors";
@import "src/styles/typography";

.button {
  @extend %link;
  font-family: 'NeueMontrealMedium';
  font-weight: 600;
  position: relative;
  padding: .6rem 1rem;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  transition-duration: 0.15s;
  overflow: hidden;

  &:after {
    content: "";
    background: hsla(0, 0%, 100%, 0.66);
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }

  &:hover, &:active {
    filter: brightness(0.9);
  }

  &__primary {
    background-color: $primary;
    text-decoration: none;
    color: $gray-900;
  }
  
  &__dark {
    background-color: $gray-900;
    text-decoration: none;
    color: $gray-100;
    a {
      color: $gray-100;
    }
  }
}
