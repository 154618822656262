@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.company {
  @media #{$mq-xl} {
    max-width: 80%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 3rem 0;
  }
  @media #{$mq-lg} {
    padding: 1rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin-top: 5vh;

  &--grid {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
  }
}

.company .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 2rem;
  min-height: 40vh;
  margin-bottom: .5rem;
  border-radius: 24px;
  background: $black;

  h1 {
    @extend %body-lg;
    text-align: left;
    color: $gray-100;
    background: $black;
    padding: .5rem;
    margin-block-end: .5rem;
    background-blend-mode: screen;
    font-weight: bolder;
  }
}

.double-column {
  display: grid;
  @media #{$mq-md} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5rem;
  }
}
.company .about-item {
  background-image: url(https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/a35b982d-a0b5-40c4-e644-7e71798a9300/public);
  background-position: center;
  background-size: cover;
}

.events-item, .work-item {
  height: 40vh;
}

.company .work-item {
  background-image: url(https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/e4c3bf08-9daf-4ca1-3a82-d1546b746000/public);
  background-position: center;
  background-size: cover;
}

.company .events-item { 
  background-image: url(https://imagedelivery.net/qdx9xDn6TxxInQGWsuRsVg/60d5cf45-1ff7-424c-6970-6f17c6794d00/public);
  background-position: center;
  background-size: cover;
}
