@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";


@mixin dark-background($dark: false) {
  @if $dark == true {
    background: $black;
  } @else {
    background: $gray-100;
  }
}

@mixin dark-text($dark: false) {
  @if $dark == true {
    color: $gray-100;
  } @else {
    color: $black;
  }
}

@mixin navbar() {
  @media #{$mq-lg} {
    justify-content: flex-start;
    padding: 0 2rem;
  }
  @media #{$mq-xxl} {
    height: 140px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  height: 60px;
  padding: .5rem 0 0 1rem;
  z-index: 3;
  top: 0;
}

.navbar {
  @include dark-background(false);
  @include navbar()
}

.navbar__dark {
  @include navbar();
  @include dark-background(true);
}

.navbar--menu {
  display: flex;
  justify-content: space-evenly;

  .menu-item {
    text-decoration: none;
    margin-right: 1.25rem;
    @include dark-text(false);
    
    &:hover {
      color: gray;
      transition: 0.2s ease-in;
    }
  }
  .menu-item__dark {
    text-decoration: none;
    margin-right: 1.25rem;
    @include dark-text(true);
    
    &:hover {
      color: gray;
      transition: 0.2s ease-in;
    }
  }
}

.brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  img {
    width: 2rem;
    height: 2rem;
  }
  .name {
    @extend %body;
    margin-left: .5rem;
    text-decoration: none;
    @include dark-text(false);
  }
  .name__dark {
    @extend %body;
    @include dark-text(true);
    margin-left: .5rem;
    text-decoration: none;
  }
}

.mobile-menu .button {
  @media #{$mq-md} {
    right: 1rem;
  }
  right: .5rem;
  display: flex;
  position: relative;
  z-index: 500;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 50%;
  padding: .55rem;
  border: none;
  cursor: pointer;
  transition: 0.24s ease-out;
  &:hover {
    background: lightgray;
  }
}


.mobile-menu--background {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: 0;
  height: 120vh;
  width: 100vw;
  background: black;
}


.navMenu {
  top: 100px;
  position: absolute;
  right: 40px;
  padding: 25px;
  max-width: 80%;
}

.navlink {
  font-size: 1.2rem;
  list-style: none;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
    
  a {
    @extend %body-lg;
    margin: 1rem 0;
  }
}

.noUnderline {
  text-decoration: none;
  color: $gray-100;
}
 
.menuToggle {
  display: block;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border: none;
  z-index: 5000;
  border: none;
  outline: none;
  background: transparent;

}