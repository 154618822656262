@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";
@import "src/styles/typography.scss";

.about {
  @media #{$mq-xl} {
    max-width: 70%;
    left: 40%;
    position: relative;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    padding: 5rem 0;
    margin: 0 $margin-lg;
  }
  @media #{$mq-lg} {
    padding: 2rem 0;
  }
  flex-direction: column;
  display: flex;
  justify-content: left;
  margin: 0 $margin-sm 0 $margin-sm;
  overflow-x: hidden;

  &--container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.header {
  @media #{$mq-sm} {
    align-items: flex-start;
    padding: 3rem 0;
  }
  @media #{$mq-lg} {
    padding: 2rem 0;
  }
  margin: 5vh 0;
  display: flex;
  flex-direction: column;

  &--title {
    @extend %h1;
    text-align: left;
    color: $black;
  }
}

.intro {
  @media #{$mq-md} {
    flex-direction: row;
  }
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem 0; 

  img {
    @media #{$mq-md} {
      width: 50%;
      object-fit: contain;
    }
    width: 100%;
  }

  &--container {
    width: 100%;
    @media #{$mq-md} {
      width: 50%;
      padding-right: 2rem;
    }

    p {
      @extend %body-lg;
      @media #{$mq-md} {
        margin: -0.5rem 0 1.5rem 0;
      }
      margin: 0 0 1rem 0;
    }
  }
}

.about-values {
  width: 100%;
  background-color: $black;
  padding: 4rem 2rem;
  margin-bottom: 2rem;
}