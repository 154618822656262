@import 'src/styles/colors';
@import 'src/styles/typography';
@import 'src/styles/breakpoints';

.audits-grid {
  @media #{$mq-md} {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
  @media #{$mq-lg} {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}


.audit-tile {
  @media #{$mq-md} {
    height: fit-content;
  }
  padding: .5rem;
  border-radius: 24px;
  transition: .1s ease-out;
  
  &:hover {
    background: $gray-100;
    filter: brightness(.9);
  }
  
  &__short_desc {
    @media #{$mq-md} {
      min-height: 3rem;
    }
    @media #{$mq-lg} {
      min-height: 5rem;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  p {
    margin: 0 0 1rem 0;
    @extend %body;
  }
  h3 {
    @extend %body-lg;
    margin: 0 0 1rem 0;
  }
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 1.5rem;
  }
}

.audits-grid .button-group {
  display: flex;
  flex-direction: row;
  a { 
    margin-right: .5rem;
  }
}
