.hover-underline{
  position: relative;
  overflow: hidden;
  user-select: none;
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: $text;
    opacity: 100;
    transition: opacity .2s, transform .2s;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  &:hover::after,
  &:focus::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
&:hover{
  filter: drop-shadow(0 0 0.01rem $text);
}
&:active{
  filter: opacity(0.95);
}
}